.intro {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.intro h1 {
  color: #000;
  font-size: 50px;
  font-weight: 700;
  width: 263px;
}

.intro p {
  color: #AFAFAF;
  font-size: 30px;
  font-weight: 300;
}

.intro h2 {
  color: var(--color-two);
  text-align: center;
  font-family: ItalianHorskey;
  font-size: 40px;
  font-weight: 400;
}

.intro span {
  color: #AFAFAF;
  font-size: 23px;
  font-weight: 300;
}

/* RESPONSIVIDADE */
@media (max-width: 1500px) {
  .intro h1 {
    width: 90%;
    text-align: center;
  }
}

