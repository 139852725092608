.link {
  text-decoration: none;
  color: black;
  font-size: 35px;
  font-weight: 700;
  transition: 350ms;
}

.linkSelect {
  color: var(--color-five);
  transition: 350ms;
}