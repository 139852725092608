.agenda {
  display: flex;
  justify-content: space-around;
  gap: 2rem;
}

.desc {
  opacity: 0.3;
}

/* RESPONSIVIDADE */
@media (max-width: 1500px) {
  .agenda {
    flex-wrap: wrap;
  }
}