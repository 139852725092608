.icon {
  position: absolute;
  top: 2vh;
  right: 2vw;

  display: flex;
  gap: 1rem;
}

.icon p {
  color: #5C5B5B;
  text-align: right;
  font-size: 20px;
  font-weight: 300;
  max-width: 120px;
}