.content_wrap {
  padding-bottom: 400px;
}

.footer_inicio {
  background-color: var(--color-two);
  height: 400px;

  display: flex;
  justify-content: space-between;

  color: white;
}

.footer_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem 5rem;
  width: 60%;
}

.contato {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.dados {
  display: flex;
  flex-direction: column;
}

.text_space {
  color: var(--color-tree);
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 13px;
  text-transform: uppercase;
  text-align: right;
  
  margin: 3rem -10px 0 0;
}

.text_destaque {
  font-size: 37.762px;
  font-weight: 300;
  text-align: right;
  margin: 0;
}

.dados h2 {
  font-size: 45px;
  font-weight: 300;
}

.whats {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.img_ivone {
  height: 90vh;
  position: absolute;
  bottom: 0;
  right: 3vw;
  transition: 650ms;
}

.img_ivone_small {
  height: 60vh;
  position: absolute;
  bottom: 0;
  right: 3vw;
  transition: 650ms;
}

.botao {
  margin-top: -5.5rem;
  width: fit-content;
  z-index: 3;
}

.botao p {
  color: var(--color-five);
  font-size: 25px;
  font-weight: 300;
  text-align: center;
  margin-top: 1rem;
}

.assinatura {
  margin-top: -10rem;
}

.assinatura h1 {
  color: white;
  font-size: 30px;
  font-weight: 700;
}

.assinatura p {
  color: var(--color-tree);
  font-size: 30px;
  font-weight: 300;
}

.assinatura h2 {
  color: white;
  font-family: ItalianHorskey;
  font-size: 40px;
  font-weight: 400;
}

.none {
  display: hide;
  opacity: 0;
}


/* RESPONSIVIDADE */
@media (max-width: 1580px) {
  .contato {
    flex-direction: column;
    width: auto;
    gap: 3rem;
  }
  .footer_inicio{
    height: 800px;
  }
  .content_wrap{
    height: 800px;
  }
  .simbolo {
    width: 50%;
    margin: auto;
  }
  .text_destaque {
    text-align: center;
  }
  .text_space {
    text-align: center;
  }
  .footer_content {
    align-items: center;
  }
  .footer_content_inicio {
    align-items: left;
    width: 80%;
  }
  .assinatura {
    margin-top: 0;
    text-align: center;
  }
  .img_ivone {
    height: 60vh;
  }
}

@media (max-width: 986px) {
  .img_ivone {
    height: 45vh;
    bottom: 800px;
    z-index: 1;
  }
  .img_ivone_small {
    height: 40vh;
    bottom: 800px;
    z-index: 1;
  }
  .footer_content,
  .footer_content_inicio {
    width: 100%;
    padding: 3rem 1rem;
  }
  .whats h2 {
    font-size: 30px;
  }
  .simbolo {
    width: 60%;
    margin: auto;
  }
  .contato {
    width: 100%;
    align-items: center;
  }
}