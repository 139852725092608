button {
  cursor: pointer;
}

.botao_agendar {
  display: inline-flex;
  padding: 20px 40px;
  justify-content: center;
  align-items: center;

  color: #FFF;
  text-align: center;
  font-size: 40px;
  font-weight: 700;

  border-radius: 10px;
  border: none;
  background: linear-gradient(90deg, #9AD884 0%, #7FC666 100%);
  transition: 350ms;
}

.botao_agendar:hover {
  color: #e6ffdd;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  transition:350ms;
}