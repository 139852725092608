@font-face {
  font-family: "ItalianHorskey";
  src: local("ItalianHorskey"),
   url("./fonts/italian-horskey.otf") format("opentype");
 }


* { 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --color-one: #DADADA;
  --color-two: #5C354D;
  --color-tree: #D3AAC5;
  --color-four: #ACBD8A;
  --color-five: #CAB492;
}

a {
  text-decoration: none;
}

body {
  background-color: #F5F5F5;
  font-family: 'Barlow', sans-serif;
}

section {
  min-height: 100vh;
  position: relative;
  display: flex;
  gap: 3rem;
}

footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}

div.content {
  margin-top: 12vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-height: 1000px;
  height: 100%;
  width: auto;
}

/* RESPONSIVIDADE */
@media (max-width: 1500px) {
  section {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  div.content {
    margin-top: 4rem;
    align-items: center;
    text-align: center;
    padding: 0 1rem;
    margin-bottom: 600px;
    min-height: 0;
  }
  
}

