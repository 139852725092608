.titulo {
  font-size: 40px;
  font-weight: 700;
}

.texto {
  font-size: 25px;
  font-weight: 300;
}

.convenios {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}

.convenios img {
  width: 200px;
  height: 200px;

  border-radius: 8px;
}

.conteudo {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}


/* RESPONSIVIDADE */
@media (max-width: 986px) {
  .conteudo {
    width: 90%;
  }
  .convenios {
    flex-direction: column;
    align-items: center;
  }
  .convenios img {
    width: 70%;
    height: auto;
  }
}