.menuHeader {
  display: flex;
  width: 396px;
  height: 400px;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  flex-shrink: 0;

  border-radius: 37px;
  border: 3px solid #DADADA;
  background: #FFF;

  margin-top: 10vh;
  margin-left: 10vw;
}

.line {
  height: 1px;
  background-color: #DADADA;
  align-self: stretch;
}


/* RESPONSIVIDADE */
@media (max-width: 1500px) {
  .menuHeader {
    margin-left: 0;
  }
}