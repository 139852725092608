.titulo {
  font-size: 40px;
  font-weight: 700;
}

.texto {
font-size: 25px;
font-weight: 300;

}

.container_img {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}

.img {
  width: 500px;
  height: 400px;
  object-fit: cover;
  border-radius: 12px;
}

/* RESPONSIVIDADE */
@media (max-width: 1720px) {
  .img {
    width: 400px;
    height: 300px;
  }
}

@media (max-width: 1500px) {
  .container_img {
    flex-direction: column;
    align-items: center;
  }
  .img {
    width: 80%;
    height: 400px;
  }
}
