.semana {
  min-width: 150px;
  height: 329px;
  flex-shrink: 0;
  flex-grow: 1;

  border-radius: 15px;
  border: 3px solid var(--pallete-color-two, #D3AAC5);
}

.dia {
  background: #EBE7E9;
  border-radius: 12px 12px 0 0;
  height: 3rem;

  padding-top: 4px;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
}

.horarios {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  margin-top: 2rem;
}

.horas {
  font-size: 20px;
  font-weight: 400;
}